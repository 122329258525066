const React = require("react")
const { ChakraProvider } = require("@chakra-ui/react")
const GlobalCSS = require("./src/shared/components/GlobalCSS").default
const theme = require("./src/shared/theme").default

/* 
  clicking anywhere in the page resets tab focus back to the top
  https://github.com/gatsbyjs/gatsby/issues/29037
  The function below fix the behaviour above. 
*/
const RemovePageTabIndex = ({ children }) => {
  React.useEffect(() => {
    const gatsbyFocusWrapper = document.querySelector("#gatsby-focus-wrapper")
    if (gatsbyFocusWrapper) {
      gatsbyFocusWrapper.removeAttribute("style")
      gatsbyFocusWrapper.removeAttribute("tabIndex")
    }
  }, [])

  return <>{children}</>
}

exports.wrapRootElement = ({ element }) => {
  return <ChakraProvider theme={theme}>{element}</ChakraProvider>
}

exports.wrapPageElement = ({ element }) => {
  return (
    <GlobalCSS>
      <RemovePageTabIndex>{element}</RemovePageTabIndex>
    </GlobalCSS>
  )
}
