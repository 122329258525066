export const Button = {
  baseStyle: {
    lineHeight: "1",
    height: "auto",
    fontSize: "16px",
    borderRadius: "sm",
  },
  sizes: {
    lg: {
      fontSize: "md",
    },
  },
  variants: {
    tranparent: props => ({
      bg: "transparent",
      color: "white",
      _hover: {
        bg: "transparent",
        color: "white",
      },
    }),
    solid: props => ({
      bg: "alpha.alertLight",
      color: "white",
      _hover: {
        bg: "alpha.activeLight",
        color: "white",
      },
    }),
    outline: props => ({
      bg: "white",
      color: "alpha.alertLight",
      borderColor: "alpha.alertLight",
      _hover: {
        bg: "alpha.neutralLight",
        color: "alpha.alertLight",
      },
    }),
    ghost: props => ({
      bg: "white",
      color: "alpha.alertLight",
      borderColor: "none",
      _hover: {
        bg: "alpha.neutralLight",
        color: "alpha.alertLight",
      },
    }),
    link: props => ({
      bg: "white",
      color: "alpha.alertLight",
      borderColor: "none",
      _hover: {
        bg: "white",
        color: "alpha.alertLight",
      },
    }),
  },
}
