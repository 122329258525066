export const fonts = {
  heading: '"GT Walsheim Pro", sans-serif',
  body: '"GT Walsheim Pro", sans-serif',
  mono: "Menlo, monospace",
}

export const fontSizes = {
  headline: {
    large: {
      desktop: "3.6rem",
      mobile: "3rem",
    },
    medium: "1.875rem",
    small: "1.5rem",
  },
  introduction: "1.25rem",
  subtitle: {
    medium: "1.125rem",
    small: "1rem",
  },
  body: {
    medium: "1.125rem",
    small: "1rem",
  },
  caption: "0.875rem",
  legal: "0.75rem",
}

export const lineHeights = {
  small: "0.9",
  normal: "normal",
  none: "1",
  heading: "1.15",
  subtitle: "1.5",
  body: "1.5",
  caption: "1.3",
  introduction: "1.4",
}
